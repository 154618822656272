import React, { useContext, useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import moment from "moment"
import "react-big-calendar/lib/css/react-big-calendar.css"
import { cartContext } from "../context/cartContext/cartContext"
import { searchContext } from "../context/searchContext/searchContext"
import { userContext } from "../context/userContext/userContext"
import axios from "axios"
import { base, URLS } from "../config/urls"
import { navigate } from "gatsby"
import SEO from "../components/seo/seo"
import { showConfirmation, showError, showMobilePrompt } from "../uitlity/Utility"
import { razorpayContext } from "../context/razorpayContext/razorpayContext"
import Loader from "../components/loader/Loader";
import Footer from "../components/footer/footer"
import Layout from "../components/layout/layout"

const localizer = momentLocalizer(moment) // or globalizeLocalizer

const BookingSchedule = (props) => {

  let subscriptionData = null
  let selectedBooking = null
  if (typeof window !== "undefined") {
    try {
      selectedBooking = props.location.state.selectedBooking
    } catch (e) {
    }

    try {
      subscriptionData = props.location.state.subscriptionData
    } catch (e) {
    }
  }


  const cartCtx = useContext(cartContext)
  const searchCtx = useContext(searchContext)
  const userCtx = useContext(userContext)
  const razorCtx = useContext(razorpayContext)
  const [salon, setSalon] = useState(null)

  const [selectedService, setSelectedService] = useState(0)
  const [selectedPackage, setSelectedPackage] = useState(0)
  const [resources, setResources] = useState([])
  const [events, setEvents] = useState([])
  const [selectedEvent, setSelectedEvent] = useState({
    packages: {},
    services: {}
  })
  const [termsAndConditions, setTermsAndConditions] = useState(false)
  const [allSlotsSelected, setAllSlotsSelected] = useState(false)
  const [customerName, setCustomerName] = useState("")
  const [mobile, setMobile] = useState("")
  const [isMale, setIsMale] = useState(false)
  const [email, setEmail] = useState("")
  const [bookingFor, setBookingFor] = useState("self")

  const [serviceList, setServiceList] = useState([])
  const [packageList, setPackageList] = useState([])
  const [salonId, setSalonId] = useState(-1)
  const [charges, setCharges] = useState(0)
  const [total, setTotal] = useState(0)
  const [discount, setDiscount] = useState(0)
  const [coupon, setCoupon] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [modifyData, setModifyData] = useState(true)
  const [paid, setPaid] = useState(false)
  const [bookingDate, setBookingDate] = useState(moment().toDate())
  const [askPhone,setAskPhone]=useState(null)

  const updateMobileNumber = (mobile) => {
    axios.patch(URLS.editProfile(userCtx.user.id),
      { phone_number: mobile },
      {
        headers: {
          Authorization: "Token " + userCtx.token
        }
      }
    ).then(resp => {
      userCtx.setUser(prev=>{
        prev.data.phone_number = mobile
        return {...prev}
      })
    }).catch(e => {
    })
  }
  const Logout = () => {
    axios({
      method: "post",
      url: URLS.logoutUrl(),
      headers: {
        Authorization: "Token " + userCtx.token
      }
    })
      .then(result => {
        navigate(base +"/login", { replace: true })
      })
      .catch(error => console.log(error))
    userCtx.setUser(null)
  }
  useEffect(() => {
    if (userCtx.user && !(userCtx.user.phone_number && userCtx.user.phone_number.length===10)) {
      showMobilePrompt("Mobile:").then(mobile => {
        if (/^[\d]{10}$/.test(mobile)) {
          updateMobileNumber(mobile)
        } else if(mobile===null) {
          showError("Mobile Number Required For LoggedIn User")
          showConfirmation("Logout",()=>{
            Logout()
          },()=>{
            setAskPhone({})
          })
        }else{
          showError("Invalid Mobile Number")
          setAskPhone({})
        }
      }).catch(e => {
        console.log(e)
      })
    }

  }, [askPhone,userCtx.user])
  let handlingCharges = 10

  const createBookingObject = () => {
    let bookingObject = {
      salon: salon,
      customerName: customerName,
      customerEmail: "",
      customerPhone: mobile,
      isMale: isMale,
      bookingDate: bookingDate.getFullYear() + "-" + bookingDate.getMonth() + "-" + bookingDate.getDate(),
      bookings: Object.keys(selectedEvent).reduce(() => {
      }, [])
    }
  }

  const setHours = (hours) => {
    let splitHours = hours.split(":")
    return new Date(bookingDate).setHours(splitHours[0], splitHours[1], splitHours[2])
  }

  useEffect(() => {
    // debugger

    let newCharges = serviceList.length && serviceList.reduce((prev, service) => prev + service.price * service.persons, 0)
    newCharges = newCharges + (packageList.length && packageList.reduce((prev, singlePackage) => prev + singlePackage.price * singlePackage.persons, 0))
    let newTotal = newCharges

    if (subscription) {
      newTotal = newCharges * subscription["paid_months"]
    }
    let newDiscount = 0
    if (coupon) {
      let { discount: discountValue, has_upper_limit, upto, is_percentage } = coupon
      if (is_percentage) {
        newDiscount = newCharges * (discountValue / 100)
      } else {
        newDiscount = discountValue
      }
      if (has_upper_limit && newDiscount > upto) {
        newDiscount = upto
      }
    }
    setCharges(newCharges)
    setDiscount(newDiscount)
    setTotal(newTotal)
    setAllSlotsSelected(false)
  }, [serviceList, packageList])

  useEffect(() => {
    if (subscriptionData) {
      let addMonth = subscriptionData.bookings.findIndex(booking => booking.id === selectedBooking)
      let startingDate = moment(subscriptionData.date)
      let startMonth = moment(startingDate).add(addMonth, "M")
      let startMonthEnd = moment(startMonth).endOf("month")
      if (startingDate.date() != startMonth.date() && startMonth.isSame(startMonthEnd.format("YYYY-MM-DD"))) {
        startMonth = startMonth.add(1, "d")
      }
      startMonth = startMonth.add(1, "d")

      setBookingDate(()=>(moment().isAfter(startMonth)?moment():startMonth).toDate())


      setSubscription(null)
      setSalonId(subscriptionData.salon.id)
      subscriptionData.services.forEach(service => service.persons = 1)
      subscriptionData.packages.forEach(singlePackage => singlePackage.persons = 1)
      setServiceList([].concat(subscriptionData.services))
      setPackageList([].concat(subscriptionData.packages))
      setCoupon(null)
      setModifyData(false)
      setPaid(subscriptionData.paid)


    } else if (cartCtx.cartValue.salon) {
      setSubscription(cartCtx.cartValue.subscription)
      setSalonId(cartCtx.cartValue.salon)
      setServiceList([].concat(cartCtx.cartValue.services))
      setPackageList([].concat(cartCtx.cartValue.packages))
      setCoupon(cartCtx.cartValue.coupon)
      if(cartCtx.cartValue.subscription){
        setModifyData(false)
      }else{
        setModifyData(true)
      }
      setPaid(false)
    }


  }, [])
  useEffect(()=>{
    if( userCtx.user && userCtx.user["phone_number"]===null){
      setBookingFor("others")
    }
  },[userCtx.user])

  useEffect(() => {
    if (salonId > 0) {
      searchCtx.getSalon(salonId).then(salon => {
        let cartValue = { ...cartCtx.cartValue }
        cartValue.services.forEach(
          service => (service.price = salon.services[service.id].price)
        )
        cartValue.packages.forEach(
          singlePackages => (singlePackages.price = salon.packages[singlePackages.id].price)
        )
        cartCtx.setCartValue(cartValue)
        setSalon(salon)
      })
    }
  }, [salonId])

  const prepareBookingObject = (payment) => {

    if (subscription) {
      let subscriptionObject = {
        "salon_id": cartCtx.cartValue.salon,
        "duration_id": cartCtx.cartValue.subscription.id,
        "date": moment(bookingDate).format("YYYY-MM-DD"),
        "bookings": Object.keys(selectedEvent).reduce((prev, type) => {
          let outerBookings = Object.keys(selectedEvent[type]).reduce((prev1, typeId) => {
            let partialBookings = selectedEvent[type][typeId].map((typeDetails) => {
              let start = new Date(typeDetails.start)
              let end = new Date(typeDetails.end)
              let internalBooking = {
                "stylist_id": typeDetails.resourceId,
                // `${type.slice(0,-1)}_id`:typeId,
                "start_time": `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`,
                "end_time": `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`
              }
              internalBooking[`${type.slice(0, -1)}_id`] = parseInt(typeId)
              return internalBooking
            })

            return prev1.concat(partialBookings)
          }, [])
          return prev.concat(outerBookings)
        }, [])
      }
      if (coupon) {
        subscriptionObject["code"] = cartCtx.cartValue.coupon
      }
      axios.post(URLS.subscriptionUrl(), subscriptionObject, {
        headers: {
          Authorization: "Token " + userCtx.token
        }
      }).then(({ data: { data } }) => {
        let subscriptionId = data["id"]
        let uuid = data["uuid"]
        ////debugger
        if (payment) {
          razorCtx.displayRazorpaySubscription({
            subscriptionId,
            "first_name": userCtx.user["first_name"],
            "phone_number": userCtx.user["phone_number"],
            "email": userCtx.user["email"]
          }, (paymentId) => {

            navigate(base +"/bookingDone", {
              replace: true,
              state: {
                salonName: salon.name,
                subscriptionId: subscriptionId,
                paymentId
              }
            })
          }, (e) => {
            showError(e)
            navigate("/bookingDone", {
              replace: true,
              state: {
                salonName: salon.name,
                subscriptionId: subscriptionId
              }
            })
          })

        } else {

          navigate("/bookingDone", {
            replace: true,
            state: {
              salonName: salon.name,
              subscriptionId: subscriptionId
            }
          })
        }
      }).catch(error => {
        let response = { ...error.response }
        if (response.status === 400) {
          showError(response.data.msg)
        } else {
          showError("Unable to reach server Please try again later")
        }
      })

    } else {
      let bookingObject = {
        "salon_id": salonId,
        "booking_date": moment(bookingDate).format("YYYY-MM-DD"),
        "bookings": Object.keys(selectedEvent).reduce((prev, type) => {
          let outerBookings = Object.keys(selectedEvent[type]).reduce((prev1, typeId) => {
            let partialBookings = selectedEvent[type][typeId].map((typeDetails) => {
              let start = new Date(typeDetails.start)
              let end = new Date(typeDetails.end)
              let internalBooking = {
                "stylist_id": typeDetails.resourceId,
                // `${type.slice(0,-1)}_id`:typeId,
                "start_time": `${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`,
                "end_time": `${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`
              }
              internalBooking[`${type.slice(0, -1)}_id`] = parseInt(typeId)
              return internalBooking
            })

            return prev1.concat(partialBookings)
          }, [])
          return prev.concat(outerBookings)
        }, []),
        "customer": {}
      }

      if (userCtx.user === null || bookingFor === "others") {//custom data
        bookingObject.customer_phone = mobile
        bookingObject.customer = {
          "name": customerName,
          "email": email,
          "phone": mobile,
          "is_male": isMale,
          "is_registered": false,
          "user_id": "2"
        }
      } else {//userData
        bookingObject.customer_phone = userCtx.user["phone_number"]
        bookingObject.customer = {
          "name": userCtx.user["first_name"] + " " + userCtx.user["last_name"],
          "email": userCtx.user["email"],
          "phone": userCtx.user["phone_number"],//take mobile from user context
          "is_male": userCtx.user["gender"] === "MALE",
          "is_registered": false,
          "user_id": userCtx.user.id
        }
      }
      if (cartCtx.cartValue.coupon) {
        bookingObject["code"] = coupon.code
      }

      if (subscriptionData) {
        bookingObject["subscription_id"] = subscriptionData.id
      }
      //////debugger
      let headers = {}
      if (userCtx.user)
        headers["Authorization"] = "Token " + userCtx.token

      axios.post(
        userCtx.user ? URLS.createBookings(bookingObject["salon_id"]) :
          URLS.createBookingPublic(bookingObject["salon_id"]),
        bookingObject, {
          headers: headers
        }).then(({ data }) => {
        let bookingId = data.data["booking_id"]
        ////debugger
        if (payment) {
          razorCtx.displayRazorpay({
            bookingId: data.data["id"],
            "first_name": bookingObject.customer.name,
            "email": bookingObject.customer.email,
            "phone_number": bookingObject.customer.phone
          }, (paymentId) => {
            // debugger
            navigate("/bookingDone", {
              replace: true,
              state: {
                salonName: salon.name,
                bookingId: bookingId,
                paymentId
              }
            })
          }, (e) => {
            navigate("/bookingDone", {
              replace: true,
              state: {
                salonName: salon.name,
                bookingId: bookingId
              }
            })
            showError(e)
          })
        } else {
          navigate("/bookingDone", {
            replace: true,
            state: {
              salonName: salon.name,
              bookingId: bookingId
            }
          })
        }

      }).catch((error) => {
        let response = { ...error.response }
        if (response.status === 400) {
          showError(response.data.msg)
        } else {
          showError("Unable to reach server Please try again later")
        }
      })
    }

    //////debugger
  }


  const serviceSelected = serviceId => {
    if (salon) {
      let activeStylists = salon.stylists.filter(stylist => {
        for (let service of stylist.services) {
          if (service === serviceId) return true
        }
      })
      let resourceMap = activeStylists.map(stylist => {
        return { resourceId: stylist.id, resourceTitle: stylist.name }
      })

      let calenderEvents = []
      ////////debugger
      salon.bookings.forEach((booking) => { // creating active booking separated by stylists
        calenderEvents.push({
          id: booking.id,
          title: "Booked",
          start: new Date(booking.date + " " + booking.start_time),
          end: new Date(booking.date + " " + booking.end_time),
          resourceId: booking.stylist.id
        })
      })
      if (!resourceMap.length) {
        showError("Sorry!, There is no Stylist available for this service")
      }
      setResources(resourceMap)
      setEvents(calenderEvents)
      setSelectedPackage(-1)
      setSelectedService(serviceId)
    }
  }
  const packageSelected = packageId => {
    if (salon) {
      let activeStylists = salon.stylists.filter(stylist => {
        for (let singlePackage of stylist.packages) {
          if (singlePackage === packageId) return true
        }
      })
      let resourceMap = activeStylists.map(stylist => {
        return { resourceId: stylist.id, resourceTitle: stylist.name }
      })

      let calenderEvents = []
      ////////debugger
      salon.bookings.forEach((booking) => { // creating active booking separated by stylists
        calenderEvents.push({
          id: booking.id,
          title: "Booked",
          start: new Date(booking.date + " " + booking.start_time),
          end: new Date(booking.date + " " + booking.end_time),
          resourceId: booking.stylist.id
        })
      })
      if (!resourceMap.length) {
        showError("Sorry!, There is no Stylist available for this service")
      }
      setResources(resourceMap)
      setEvents(calenderEvents)
      setSelectedPackage(packageId)
      setSelectedService(-1)
    }
  }

  const checkUSerData = () => {
    if (!customerName.length) {
      showError("Name Is Required")
      return false
    }
    if (!/^([a-z0-9_\-\.])+\@([a-z0-9_\-])+\.([a-z]{2,})$/.test(email)) {
      showError("Enter valid email")
      return false
    }
    if (!/^\d{10}$/.test(mobile)) {
      showError("Enter valid mobile")
      return false
    }
    return true
  }

  const ShowCalander = () => {
    return (
      // (selectedService > 0 || selectedPackage > 0) && resources.length) ? (
      <div className="pl4-ns">

        <Calendar
          // min={new Date(setHours(salon["opening_time"]))}
          // max={new Date(setHours(salon["closing_time"]))}
          // defaultDate={bookingDate}
          onSelecting={() => false}
          selectable={"ignoreEvents"}
          onSelectSlot={data => {
            if (subscriptionData) {
              let addMonth = subscriptionData.bookings.findIndex(booking => booking.id === selectedBooking)
              let startingDate = moment(subscriptionData.date)
              let startMonth = moment(startingDate).add(addMonth, "M")
              let startMonthEnd = moment(startMonth).endOf("month")
              if (startingDate.date() != startMonth.date() && startMonth.isSame(startMonthEnd.format("YYYY-MM-DD"))) {
                startMonth = startMonth.add(1, "d")
              }
              let finishMonth = moment(startingDate).add(addMonth + 1, "M")
              let finishMonthEnd = moment(finishMonth).endOf("month")
              if (startingDate.date() != finishMonth.date() && finishMonth.isSame(finishMonthEnd.format("YYYY-MM-DD"))) {
                finishMonth = finishMonth.add(1, "d")
              }

              if (moment(bookingDate).isAfter(finishMonth) || moment(bookingDate).isBefore(startMonth)) {
                showError(`Please select date between ${startMonth.format("DD-MM-YYYY")} and ${finishMonth.format("DD-MM-YYYY")}`)
                return
              }
            }

            let baseKey = ""
            let baseList = []
            let selectedId = -1

            if (selectedService > 0) {
              baseKey = "services"
              baseList = serviceList
              selectedId = selectedService
            } else if (selectedPackage > 0) {
              baseKey = "packages"
              baseList = packageList
              selectedId = selectedPackage
            }

            data.end = new Date(data.start)
            data.end.setMinutes(
              data.end.getMinutes() +
              salon[baseKey][selectedId].time_duration
            )

            let c1 = moment(data.start).isAfter(moment())
            let c2 = moment(data.end).isBefore(moment(setHours(salon["closing_time"])))
            let c3 = moment(data.end).isSame(moment(setHours(salon["closing_time"])))
            let c4 = data.box && data.action === "click"
            // //////debugger
            // if( moment(data.start).isAfter(moment()) && (moment(data.end).isBefore(moment(salon.closingTime))||moment(data.end).isSame(moment(salon.closingTime))) && data.box && data.action==='click'){
            if (c1 && (c2 || c3) && c4) {
              let persons = baseList.filter(({ id }) => id === selectedId)[0].persons
              let selectedEvents = { ...selectedEvent }
              if (!selectedEvents[baseKey][selectedId]) selectedEvents[baseKey][selectedId] = []
              if (selectedEvents[baseKey][selectedId].length === persons) selectedEvents[baseKey][selectedId].shift()
              selectedEvents[baseKey][selectedId].push(data)
              setSelectedEvent(selectedEvents)
              setAllSlotsSelected(false)
            }
          }}
          // events={events}
          events={Object.keys(selectedEvent).reduce((prev, type) => {
            return Object.keys(selectedEvent[type]).reduce((prev1, typeId) => {
              return prev1.concat(selectedEvent[type][typeId])
            }, prev)
          }, [...events])}
          // events={Object.keys(selectedEvent).reduce((prev, serviceId) => {
          //   return prev.concat(selectedEvent[serviceId])
          // }, [...events])}
          localizer={localizer}
          defaultView={Views.DAY}
          views={["day"]}
          step={15}
          timeslots={4}
          components={{
            toolbar: CustomToolbar
          }}
          resources={resources}
          resourceIdAccessor="resourceId"
          resourceTitleAccessor="resourceTitle"
          onNavigate={(data, flipUnit, prevOrNext) => {
            if (prevOrNext === "NEXT")
              setBookingDate(()=>{
                let nextDate = moment(bookingDate)
                while(true){
                  nextDate = nextDate.add(1, "d")
                  if(!(salon["upcoming_holidays"] && salon["upcoming_holidays"].includes(nextDate.format("YYYY-MM-DD")))){
                    break
                  }
                }
                return new Date(nextDate.toDate());
              })
            else
              setBookingDate(()=>{
                let nextDate = moment(bookingDate)
                while(true){
                  nextDate = nextDate.subtract(1, "d")
                  if(!(salon["upcoming_holidays"] && salon["upcoming_holidays"].includes(nextDate.format("YYYY-MM-DD")))){
                    break
                  }
                }
                return new Date(nextDate.toDate());
              })
            setAllSlotsSelected(false)
            setSelectedEvent({ packages: {}, services: {} })
          }}
        />
      </div>
    )
  // : <div className="pl4-ns">
  //     <h3>Select service to continue</h3>
  //   </div>
  }


  let content = null
  if (!(cartCtx.cartValue.salon || subscriptionData))
    content = <h1>Please add data to cart first!!</h1>
  else if (!salon) {
    content = <Loader/>
  }

  return (
    <>
    <Layout>
        <SEO title="Listing" />
      <div className="uk-section uk-section-muted" style={{minHeight: "calc(100vh - 80px)"}}>
        <div className="uk-container uk-container-medium">
          <div className="uk-card uk-card-default uk-card-body">
            <h2 className="uk-text-large">{"Salon Name"}</h2>
            <div className="flex flex-column flex-row-ns mt4">
              <div className="w-100 w-40-ns">

                <ul className="uk-list uk-list-divider mt4">
                  {serviceList.map(service => (
                    <>
                      <li className="w-100">
                        <div id="toggle-custom" className="uk-card checkout-service-card-bg pa3 w-100">
                          <label className="flex flex-column flex-row-ns items-center-ns w-100">
                            <input className="uk-checkbox"
                                   onClick={() => serviceSelected(service.id)}
                                   checked={selectedService === service.id}
                                   type="checkbox"/>
                            <div className="flex flex-column flex-row-ns justify-between items-center-ns pb2 w-100">
                              <div className="ml3-ns">
                                <div className="uk-text-bold">
                                  {service.name}
                                </div>
                                <div className="uk-text-muted">
                                  {service.time_duration} Minutes
                                </div>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="mh3-ns mv3 mv0-ns">
                                  <div className="uk-text-bold" style={{ whiteSpace: "nowrap" }}>
                                    ₹ {service.price}
                                  </div>
                                  {!subscriptionData && <div className="uk-text-muted mt2">
                                    <span className="uk-margin-small-right cursor-pointer"
                                          onClick={() => setServiceList(cartCtx.removeFromList(serviceList, service.id))}
                                          data-uk-icon="trash"/>
                                  </div>}
                                </div>
                                <div className="ml3-ns uk-text-center">
                                  <div className="uk-text-bold">
                                    No. of People
                                  </div>
                                  <div className="flex mt2">
                                    {modifyData && <button className="uk-button uk-button-small"
                                                           onClick={() => {
                                                             //debugger
                                                             if (service.persons > 1 &&
                                                               selectedEvent.services[service.id] &&
                                                               selectedEvent.services[service.id].length > (service.persons - 1)) {
                                                               let selectedEvents = { ...selectedEvent }
                                                               selectedEvents.services[service.id].shift()
                                                               setSelectedEvent(selectedEvents)
                                                             }
                                                             setServiceList(cartCtx.removePersonFromList(serviceList, service.id))
                                                           }}>-
                                    </button>}
                                    <div className="ph2 mh2 uk-text-muted">
                                      {service.persons}
                                    </div>
                                    {modifyData && <button className="uk-button uk-button-small"
                                                           onClick={() => {
                                                             setServiceList(cartCtx.addPersonToList(serviceList, service.id))
                                                           }}>+
                                    </button>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>
                <ul className="uk-list uk-list-divider mt4">
                  {packageList.map(singlePackage => (
                    <>
                      <li className="w-100">
                        <div id="toggle-custom" className="uk-card checkout-service-card-bg pa3 w-100">
                          <label className="flex flex-column flex-row-ns items-center-ns w-100">
                            <input className="uk-checkbox"
                                   onClick={() => packageSelected(singlePackage.id)}
                                   checked={selectedPackage === singlePackage.id}
                                   type="checkbox"/>
                            <div className="flex flex-column flex-row-ns justify-between items-center-ns pb2 w-100">
                              <div className="ml3-ns">
                                <div className="uk-text-bold">
                                  {singlePackage.name}
                                  {singlePackage.services.map(({ name }) => (<p>{name}</p>))}
                                </div>
                                <div className="uk-text-muted">
                                  {singlePackage.time_duration} Minutes
                                </div>
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="mh3-ns mv3 mv0-ns">
                                  <div className="uk-text-bold" style={{ whiteSpace: "nowrap" }}>
                                    ₹ {singlePackage.price}
                                  </div>
                                  {!subscriptionData && <div className="uk-text-muted mt2">
                                    <span className="uk-margin-small-right cursor-pointer"
                                          onClick={() => setPackageList(cartCtx.removeFromList(packageList, singlePackage.id))}
                                          data-uk-icon="trash"/>
                                  </div>}
                                </div>
                                <div className="ml3-ns uk-text-center">
                                  <div className="uk-text-bold">
                                    No. of People
                                  </div>
                                  <div className="flex mt2">
                                    {modifyData && <button className="uk-button uk-button-small"
                                                           onClick={() => {
                                                             if (singlePackage.persons > 1 &&
                                                               selectedEvent.packages[singlePackage.id] &&
                                                               selectedEvent.packages[singlePackage.id].length > (singlePackage.persons - 1)) {
                                                               let selectedEvents = { ...selectedEvent }
                                                               selectedEvents.packages[singlePackage.id].shift()
                                                               setSelectedEvent(selectedEvents)
                                                             }
                                                             setPackageList(cartCtx.removePersonFromList(packageList, singlePackage.id))
                                                           }}>-
                                    </button>}
                                    <div className="ph2 mh2 uk-text-muted">
                                      {singlePackage.persons}
                                    </div>
                                    {modifyData && <button className="uk-button uk-button-small"
                                                           onClick={() => {
                                                             setPackageList(cartCtx.addPersonToList(packageList, singlePackage.id))
                                                           }}>+
                                    </button>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </li>
                    </>
                  ))}
                </ul>

              </div>
              <div className="w-100 w-60-ns">
                {
                  ShowCalander()
                }
              </div>
            </div>
            <div className="flex flex-column flex-row items-end">
              <div className="w-100 w-40-ns flex flex-wrap">
                <div className="ma2">
                  <button className="uk-button uk-button-primary" type="button"
                          onClick={() => {
                            //debugger
                            let allSelected = true
                            if (!termsAndConditions) {
                              showError("Please accept terms and conditions first")
                              return
                            }

                            for (let service of serviceList) {
                              if (!selectedEvent.services[service.id] || (service.persons !== selectedEvent.services[service.id].length)) {
                                allSelected = false
                                showError("Please select all time slots for " + service.name)
                                return
                              }
                            }
                            for (let singlePackage of packageList) {
                              if (!selectedEvent.packages[singlePackage.id] || (singlePackage.persons !== selectedEvent.packages[singlePackage.id].length)) {
                                allSelected = false
                                showError("Please select all time slots for " + singlePackage.name)
                                return
                              }
                            }
                            setAllSlotsSelected(allSelected)
                          }}>
                    Pay & Book Appointment
                  </button>
                </div>
                <div className="ma2">
                  <button className="uk-button uk-button-secondary" onClick={() => {
                    if (typeof window !== "undefined") window.history.go(-1)
                  }}>
                    Cancel
                  </button>
                </div>
              </div>
              <div className="w-100 w-60-ns pl4-ns">
                <label htmlFor="termsAndConditions">
                  <input class="uk-checkbox" id={"termsAndConditions"} type="checkbox" checked={termsAndConditions}
                         onChange={() => {
                           setAllSlotsSelected(false)
                           setTermsAndConditions(!termsAndConditions)
                         }}/>
                  <span> </span> Terms And Conditions</label>
              </div>
            </div>
            <hr/>
            {
              (allSlotsSelected &&
                termsAndConditions) &&
              <div className="flex flex-column flex-row-ns pv4">
                <div className="w-100 w-50-ns">
                  <legend className="uk-legend uk-text-bold uk-text-secondary">
                    {(userCtx.user && userCtx.user["phone_number"]) ? "Booking For?" : "Contact Details"}
                  </legend>
                  <div className="flex pv3">
                    <form>
                      <fieldset className="uk-fieldset">
                        <div className="uk-margin">
                          {(userCtx.user && userCtx.user["phone_number"]) && <>
                            <label className="mr2">
                              <input
                                type="radio" value={"self"}
                                checked={bookingFor === "self"}
                                onClick={() => setBookingFor("self")}
                                disabled={subscription != null}
                              />
                              Yourself</label>
                            <label className="ml2">
                              <input
                                type="radio"
                                value={"others"}
                                checked={bookingFor === "others"}
                                onClick={() => setBookingFor("others")}
                                disabled={subscription != null}/> Someone
                              Else</label>
                          </>
                          }
                        </div>
                        {(userCtx.user === null || (bookingFor === "others")) && <>
                          <div className="uk-margin">
                            <input className="uk-input "
                                   placeholder="Full Name"
                                   type="text"
                                   value={customerName}
                                   onChange={event => setCustomerName(event.target.value)}
                            />
                          </div>
                          <div className="uk-margin">
                            <input className="uk-input "
                                   placeholder="Email Address"
                                   type="tel"
                                   value={email}
                                   onChange={event => setEmail(event.target.value)}
                            />
                          </div>
                          <div className="uk-margin">
                            <input className="uk-input "
                                   placeholder="Phone Number"
                                   type="tel"
                                   value={mobile}
                                   onChange={event => setMobile(event.target.value)}
                            />
                          </div>
                          <div className="uk-margin">
                            <label htmlFor="isMale">isMale</label>
                            <input id={"isMale"}
                                   type="checkbox"
                                   checked={isMale}
                                   onChange={() => setIsMale(!isMale)}
                            />
                          </div>
                        </>}
                      </fieldset>
                    </form>
                  </div>
                </div>
                <div className="w-100 w-50-ns">
                  <div>
                    <table className="uk-table uk-table-small w-100 uk-background-muted">
                      {serviceList.map(service => <tr>
                        <td>
                          {service.name}
                        </td>
                        <td>
                          ₹ {service.price * service.persons}
                        </td>
                        <td>
                          No. of person {service.persons}
                        </td>
                      </tr>)
                      }
                    </table>
                  </div>
                  <div className="ph2 uk-text-muted">
                    <div>
                      Charges : ₹ {charges.toFixed(2)}
                    </div>
                    {subscription && <div>
                      Subscription : {subscription["paid_months"]} Months paid
                      + {subscription["free_months"]} months free
                    </div>
                    }
                    {subscription && <div>
                      Total : {total.toFixed(2)}
                    </div>
                    }
                    {coupon && <div>
                      Coupon : {coupon.code}
                    </div>}
                    <div>
                      Discount : ₹ {discount.toFixed(2)}
                    </div>
                    <div>
                      Handling Charges : ₹ {handlingCharges.toFixed(2)}
                    </div>
                    <div>
                      Taxes : ₹ {((total - discount + handlingCharges) * 0.18).toFixed(2)}
                    </div>
                  </div>
                  <hr/>
                  <div className="ph2">
                    <div className="uk-text-bold">
                      Total Amount : ₹ {((total - discount + handlingCharges) * 1.18).toFixed(2)}
                    </div>
                  </div>
                  {!paid ?
                    <div className="flex flex-wrap">
                      <div className="ma2">
                        <button href="#booking-confirmation" className="uk-button uk-button-primary" type="button"
                                disabled={!razorCtx.available}
                                onClick={() => {
                                  //debugger
                                  if (userCtx.user === null || bookingFor === "others") {
                                    if (!checkUSerData()) {
                                      return null
                                    }
                                  }
                                  prepareBookingObject(true)
                                }}
                        >
                          Pay Online
                        </button>
                      </div>
                      { !cartCtx.cartValue.subscription &&<div className="ma2">
                        <button className="uk-button uk-button-secondary"
                                disabled={!razorCtx.available}
                                onClick={() => {
                                  if (userCtx.user === null || bookingFor === "others") {
                                    if (!checkUSerData()) {
                                      return
                                    }
                                  }
                                  prepareBookingObject(false)
                                }}
                        >
                          Pay at Venue
                        </button>
                      </div>}
                    </div> :
                    <div className="flex flex-wrap">
                      <div className="ma2">
                        <button className="uk-button uk-button-secondary"
                                disabled={!razorCtx.available}
                                onClick={() => {
                                  if (userCtx.user === null || bookingFor === "others") {
                                    if (!checkUSerData()) {
                                      return null
                                    }
                                  }
                                  prepareBookingObject(false)
                                }}
                        >
                          Confirm Booking
                        </button>
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </Layout>
    </>
  )
}

const CustomToolbar = props => {
  const navigate = action => {
    props.onNavigate(action)
  }

  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <button
          type="button"
          onClick={() => navigate("PREV")}
          style={{ marginLeft: "100px", borderColor: "white" }}
        >
          Previous Day
        </button>
        <span className="rbc-toolbar-label">{props.label}</span>
        <button
          type="button"
          onClick={() => navigate("NEXT")}
          style={{ marginLeft: "10px", borderColor: "white" }}
        >
          Next Day
        </button>
      </span>
    </div>
  )
}

export default BookingSchedule
